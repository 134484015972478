<div class=""
     ngbDropdown
     #exportResultsDropdown="ngbDropdown"
     container="body"
     placement="bottom-end"
     autoClose="outside"
>
    <span class="header-icon fa-3x fa-layers fa-fw dropdown-non-toggle-arrow"
          ngbTooltip="Click to open My Export"
          id="exportResults"
          container="body"
          ngbDropdownToggle
    >
        <fa-icon [icon]="'file-export'"></fa-icon>
        <span class="fa-layers-counter"
              *ngIf="exportService.itemsCount > 0"
              [ngStyle]="{ 'background-color': exportService.hasDone?'green':'Tomato' }"
        >
            {{exportService.itemsCount}}
        </span>
    </span>
    <ul class="export-results p-0 rounded-0"
        ngbDropdownMenu
        aria-labelledby="exportResults"
    >
        <li *ngFor="let item of exportService.allExportResults | orderBy:['status', 'createdAt']: ['asc', 'asc']; trackBy: trackByFunction"
            class="border-1 border-bottom ps-2 pe-1"
            ngbDropdownItem
            [ngClass]="{
                   'item-row-negative': item.status === ExportStatusEnum.failed
                 }"
        >
            <div class="d-flex justify-content-between align-items-start">
                <div class="fw-bold">
                    <div class="text-truncate"
                         [ngbTooltip]="item.exportName?.length > 26 ? item.exportName : null"
                         container="body"
                    >
                        <app-copy-cell [data]="item.jobId" [iconOnly]="true"/>
                        {{item.exportName}}
                    </div>
                    <div class="align-items-center"
                         [ngClass]="{
                         'text-success': item.status === ExportStatusEnum.done,
                         'text-danger': item.status === ExportStatusEnum.failed
                         }"
                         *ngIf="isFinalStatus(item.status); else processingTmpl">
                        {{item.status | bTitleCase}}
                        <div ngbDropdown autoClose="outside" container="body"
                             #exportResultPathsDropdown="ngbDropdown"
                             *ngIf="item.paths?.length > 1"
                             class="d-inline-flex"
                             (click)="$event.preventDefault()"
                        >
                            <button type="button"
                                    class="btn btn-sm btn-light text-success"
                                    ngbDropdownToggle
                            >
                                <fa-icon [icon]="'download'"/>
                            </button>
                            <div ngbDropdownMenu>
                                <button class="btn btn-sm btn-light text-success ms-1 text-truncate text-truncate-lg"
                                        *ngFor="let path of item.paths"
                                        ngbDropdownItem
                                        (click)="download(path);exportResultPathsDropdown.close()"
                                        [ngbTooltip]="path"
                                >
                                    {{path}}
                                </button>
                            </div>
                        </div>
                        <button class="btn btn-sm btn-light text-success ms-1"
                                *ngIf="item.paths?.length === 1"
                                (click)="download(item.paths?.[0]);exportResultsDropdown.close()"
                                [ngbTooltip]="item.paths?.[0]"
                        >
                            <fa-icon [icon]="'download'"/>
                        </button>
                        <ng-template #infoTooltipTmpl>
                            <div *ngFor="let item of (item?.info || {}) | keyvalue : coreService.originalOrder">
                                <b class="fw-bold">{{ item.key }}: </b>
                                <span *ngIf="getInfoTitle(item?.value) as data; else simpleValueStr">{{data}}</span>
                                <ng-template #simpleValueStr>{{ item.value === 0 ? item?.value : (item?.value ?? 'No info') }}</ng-template>
                            </div>
                        </ng-template>
                        <button class="btn btn-sm btn-light text-info ms-1"
                                *ngIf="item?.info as info"
                                [ngbPopover]="infoTooltipTmpl"
                                triggers="mouseenter:mouseleave"
                                container="body"
                                [appCopyClipboard]="info | json"
                                (copied)="toastrService.successNotify('Copied to clipboard')"
                                title="click to copy"
                        >
                            <fa-icon icon="question-circle"/>
                        </button>
                    </div>
                    <ng-template #processingTmpl>
                        <div class="text-brand d-inline-flex align-items-center">
                            {{item.status | bTitleCase}}
                            <fa-icon [icon]="'spinner'" [animation]="'spin'" class="px-2 py-1"/>
                        </div>
                    </ng-template>
                </div>
                <div class="">

                    <ng-template #dateTooltipTmpl>
                        <div *ngIf="item.createdAt as date">
                            <b>Created at:</b> {{date | bdate: 'dateTime'}}
                        </div>
                        <div *ngIf="item?.startedAt as date">
                            <b>Started at:</b> {{date | bdate: 'dateTime'}}
                        </div>
                        <div *ngIf="item?.updatedAt as date">
                            <b>Last Update:</b> {{date | bdate: 'dateTime'}}
                        </div>
                    </ng-template>
                    <span class="fs-xs cursor-pointer"
                          [ngbPopover]="dateTooltipTmpl"
                          triggers="mouseenter:mouseleave"
                          container="body"
                    >
                        {{item.createdAt | bdate:'time'}}
                    </span>
                    <button class="btn btn-sm btn-light"
                            (click)="remove(item)"
                            [disabled]="item.loading || !isFinalStatus(item.status)"
                    >
                        <fa-icon [icon]="'xmark'" *ngIf="!item?.loading"/>
                        <app-inline-loader *ngIf="item?.loading"/>
                    </button>
                </div>
            </div>
        </li>
        <li class="ngbDropdownItem text-center p-2 fs-lg"
            *ngIf="!exportService.itemsCount"
        >
            No exports yet
        </li>
    </ul>
</div>
